
import { useRoute, useRouter } from "vue-router";
import { defineComponent, onMounted, ref } from "vue";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

import SelectedSupplies from "@/views/crafted/pages/repairing-plans/selected-supplies/SelectedSupplies.vue";

import { RepairingPlanService } from "@/core/services/RepairingPlan.service";
import {
  ProductItemResponse,
  SupplyWithPivotResponse,
} from "@/core/interfaces/ApiResponses";
import { SupplyItemForRepairingPayload } from "@/core/interfaces/Models";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "repairing-plan-detail",
  components: { SelectedSupplies },
  data() {
    return {
      formatDate: moment,
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    // Local state
    const planId = ref(0);
    const planName = ref("");
    const planMonth = ref(0);
    const planNote = ref("");
    const productItem = ref<ProductItemResponse>({
      id: 0,
      ten_san_pham: "",
      ma_san_pham: "",
      so_hieu: "",
      mo_ta: "",
      ghi_chu: "",
      slug: "",
      id_nguoi_dung: 0,
      san_pham_meta: [],
      updated_at: "",
      created_at: "",
    });

    const selectedSupplies = ref<SupplyItemForRepairingPayload[]>([]);

    onMounted(async () => {
      setCurrentPageTitle("Kế hoạch sửa chữa");
      planId.value = +route.params.id;

      try {
        const {
          data: { data: planResponse },
        } = await RepairingPlanService.get(planId.value);

        planName.value = planResponse.ten_ke_hoach;
        planMonth.value = planResponse.thang;
        planNote.value = planResponse.ghi_chu;

        productItem.value = planResponse.san_pham;

        selectedSupplies.value = [];

        planResponse.dinh_muc_co_dinh.forEach(
          (item: SupplyWithPivotResponse) => {
            selectedSupplies.value.push({
              quantity: item.pivot.so_luong,
              id: item.id,
              is_valid: item.is_valid,
              ten_vat_tu: item.ten_vat_tu,
              ma_vat_tu: item.ma_vat_tu,
              so_luong: item.so_luong,
              don_vi_tinh: item.don_vi_tinh,
              don_gia: item.don_gia,
              muc_du_tru_toi_thieu: item.muc_du_tru_toi_thieu,
              muc_du_tru_toi_da: item.muc_du_tru_toi_da,
              thu_hoi: item.thu_hoi,
              cong_ty_duyet: item.cong_ty_duyet,
              id_tai_khoan: item.id_tai_khoan,
              nam: item.nam,
              slug: item.slug,
              updated_at: item.updated_at,
              created_at: item.created_at,
              vat_tu_meta:
                item.vat_tu_meta?.length > 0 ? [...item.vat_tu_meta] : [],
              ma_ct: item.ma_ct || "",
              ma_tk: item.ma_tk || "",
              ten_tk: item.ten_tk || "",
              so_du_dau_nam: item.so_du_dau_nam || 0,
            });
          }
        );
      } catch {
        Swal.fire({
          text: "Có lỗi xảy ra, mời bạn thử lại sau",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Đã hiểu!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        router.push("/trang-chu");
      }
    });

    return {
      planId,
      planName,
      planMonth,
      planNote,
      selectedSupplies,
      productItem,
    };
  },

  methods: {
    newDissolutionReportClickedHandler() {
      this.$router.push(
        `/quan-ly/bien-ban-giai-the/ke-hoach/${this.$route.params.id}/tao-moi`
      );
    },
  },
});
